<template>
  <div class="text-center mb-1">
    <div
      class="mb-3 mt-4"
    >
      <b-spinner
        style="width: 3rem; height: 3rem;"
        label="Cargando..."
        variant="primary"
        class="mt-2"
      />
    </div>
    <div
      class="mb-4"
    >
      <strong class="text-primary">Cargando...</strong>
    </div>
  </div>
</template>

<script>
import {
  BSpinner,
} from 'bootstrap-vue'

export default {
  components: {
    BSpinner,
  },
}
</script>
